import { useEffect, useState } from 'react'
import { Collapsible, Link, Message } from 'components'
import { MdOutlineAssignmentReturn, MdSettings } from 'react-icons/md'
import { GoPackage } from 'react-icons/go'
import { FaClipboardList } from 'react-icons/fa'
import { IoMdArrowDropdown } from 'react-icons/io'
import style from './Sidebar.module.css'
import Logo from 'assets/svg/Logo'

const Sidebar = ({ open, onToggle, pageId }) => {
  const handleClick =
    (href) =>
    ({ navigate }) => {
      navigate(href)
      if (window.innerWidth < 576) onToggle()
    }
  return (
    <div className={style['sidebar__wrap']} style={{ width: open ? 250 : 0 }}>
      <div className={style['sidebar']}>
        <Brand />
        <NavLink
          icon={<GoPackage />}
          label="product.title.list"
          onClick={handleClick('product')}
          active={['product-list', 'product-page'].includes(pageId)}
        />
        <NavGroup
          icon={<MdOutlineAssignmentReturn />}
          label="sidebar.refund"
          active={['refund-list', 'refund-page', 'refund-input-page'].includes(
            pageId,
          )}
        >
          <NavLink
            label="refund.title.list"
            onClick={handleClick('refund')}
            active={['refund-list', 'refund-page'].includes(pageId)}
          />
          <NavLink
            label="refund.title.input"
            onClick={handleClick('refund/input')}
            active={['refund-input-page'].includes(pageId)}
          />
        </NavGroup>
        <NavLink
          icon={<FaClipboardList />}
          label="inventory.title.list"
          onClick={handleClick('inventory')}
          active={['inventory-list'].includes(pageId)}
        />
        <NavGroup
          icon={<MdSettings />}
          label="sidebar.setting"
          active={['warehouse-list', 'staff-list', 'config-page'].includes(
            pageId,
          )}
        >
          {/* <NavLink
            label="config.title.edit"
            onClick={handleClick('config')}
            active={['config-page'].includes(pageId)}
          /> */}
          <NavLink
            label="staff.title.list"
            onClick={handleClick('staff')}
            active={['staff-list'].includes(pageId)}
          />
          <NavLink
            label="warehouse.title.list"
            onClick={handleClick('warehouse')}
            active={['warehouse-list'].includes(pageId)}
          />
        </NavGroup>
      </div>
    </div>
  )
}

const Brand = () => (
  <div className={style['sidebar__logo']}>
    <div className={style['sidebar__logo__icon']}>
      <Logo color="#fff" />
    </div>
    <Message className={style['sidebar__logo__text']} id="app.name" />
  </div>
)

const NavGroup = ({ active, icon, label, children }) => {
  const [open, setOpen] = useState(active)

  useEffect(() => {
    setOpen(active)
  }, [active])

  return (
    <>
      <Link
        className={{
          link: style['sidebar__group'],
          'link--active': active && style['sidebar__group--active'],
          'link__icon--left': style['sidebar__group__icon--left'],
        }}
        leftIcon={icon}
        rightIcon={<IoMdArrowDropdown />}
        label={label}
        onClick={() => setOpen(!open)}
      />
      <Collapsible className={style['sidebar__group__content']} open={open}>
        {children}
      </Collapsible>
    </>
  )
}

const NavLink = ({ icon, label, onClick, active }) => (
  <Link
    className={{
      link: style['sidebar__link'],
      'link--active': active && style['sidebar__link--active'],
      'link__icon--left': style['sidebar__link__icon--left'],
    }}
    leftIcon={icon}
    label={label}
    onClick={onClick}
  />
)

export default Sidebar
