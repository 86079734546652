import React, { forwardRef } from 'react'
import useGlobalState from 'hooks/useGlobalState'
import { TextInput } from 'components'
import { formatDate } from 'utilities/date'

const DateInput = (
  { show = true, id, min, max, role, value, ...props },
  ref,
) => {
  const { local } = useGlobalState()
  const { staff } = local.state
  if (staff?.staffType === 'OWNER') {
    min = '1970-01-01'
  }

  if (role && staff?.permissions.includes(role)) {
    min = '1970-01-01'
  }

  return (
    <TextInput
      show={show}
      ref={ref}
      type="date"
      min={min || getMinDate()}
      max={max || '9999-12-31'}
      value={value || ''}
      {...props}
    />
  )
}

export default forwardRef(DateInput)

function getMinDate() {
  const newDate = new Date()
  newDate.setDate(newDate.getDate() - 7)
  return formatDate(newDate)
}
