const warehouse = {
  'module.warehouse': '倉別',
  'warehouse.exist': '倉別已存在！',
  'warehouse.title.list': '倉別目錄',
  'warehouse.title.add': '新增倉別',
  'warehouse.title.edit': '修改倉別',
  'warehouse.field.name': '倉別名稱',
  'warehouse.field.type': '機制',
  'warehouse.field.allowedRatio': '允收效期百分比',
  'warehouse.field.allowedDate': '允收效期天數',
  'warehouse.field.instantAlert': '即期通知',
  'warehouse.field.isStock': '可用庫存',
}

export default warehouse
