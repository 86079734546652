import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Layout, ConfirmModal } from 'containers'
import { LoadingLogo, LoadingBar, Notification } from 'components'
import Login from 'views/Login/Login'

const ConfigPage = lazy(() => import('views/ConfigPage/ConfigPage'))
const WarehouseList = lazy(() => import('views/WarehouseList/WarehouseList'))
const StaffList = lazy(() => import('views/StaffList/StaffList'))
const ProductList = lazy(() => import('views/ProductList/ProductList'))
const ProductPage = lazy(() => import('views/ProductPage/ProductPage'))
const RefundList = lazy(() => import('views/RefundList/RefundList'))
const RefundPage = lazy(() => import('views/RefundPage/RefundPage'))
const RefundInputPage = lazy(() =>
  import('views/RefundInputPage/RefundInputPage'),
)
const InventoryList = lazy(() => import('views/InventoryList/InventoryList'))

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingLogo />}>
        <Routes>
          <Route index path="/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route path="config" element={<ConfigPage />} />
            <Route path="warehouse" element={<WarehouseList />} />
            <Route path="staff" element={<StaffList />} />
            <Route path="product" element={<ProductList />} />
            <Route path="product/:profile" element={<ProductPage />} />
            <Route path="product/:id/:profile" element={<ProductPage />} />
            <Route path="refund" element={<RefundList />} />
            <Route path="refund/input" element={<RefundInputPage />} />
            <Route path="refund/:profile" element={<RefundPage />} />
            <Route path="refund/:id/:profile" element={<RefundPage />} />
            <Route path="inventory" element={<InventoryList />} />
          </Route>
        </Routes>
      </Suspense>
      <LoadingBar />
      <Notification />
      <ConfirmModal />
    </BrowserRouter>
  )
}

export default App
