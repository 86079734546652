import cx from 'classnames'
import { Message } from 'components'
import style from './Section.module.css'

const Section = ({
  className = {},
  show = true,
  title,
  toolbar,
  columnCount = 1,
  children,
  hideShadow = false,
  ...props
}) => {
  if (!show) return null

  const getContent = () => {
    if (columnCount === 0) return children
    const gridCol = Array(columnCount).fill('1fr').join(' ')
    return (
      <div
        className={style['section__content__column']}
        style={{ gridTemplateColumns: gridCol }}
      >
        {children}
      </div>
    )
  }

  return (
    <div className={style.section} {...props}>
      {title && (
        <Message as="h1" className={style['section__title']} id={title} />
      )}
      {toolbar}
      <div
        className={cx(
          style['section__content'],
          { [style['section__content--shadow']]: !hideShadow },
          className.content,
        )}
      >
        {getContent()}
      </div>
    </div>
  )
}

export default Section
