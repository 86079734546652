import React, { useState, cloneElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useGlobalState from 'hooks/useGlobalState'
import { getColumns } from './tableAction'
import { getInitPagination, getPagination } from './paginationAction'
import { getInitFilterVals } from './filterAction'
import { FILTER_SET, PAGINATION_SET } from 'constants/actionType'
import { Button, Message } from 'components'
import Filter from './Filter'
import Pagination from './Pagination'
import { MdAdd, MdCheck, MdClose } from 'react-icons/md'
import { FaInbox } from 'react-icons/fa'
import style from './Table.module.css'

const Table = ({
  className = {},
  profile = 'view',
  showSeq = false,
  showAddInput = false,
  showAddButton = false,
  showEditButton = false,
  showDeleteButton = false,
  showFilterDrawer = true,
  filters,
  addValues = {},
  editValues = {},
  columns,
  rows,
  rowCount = 0,
  footer,
  onChange,
  onAddChange,
  onAddSubmit,
  onEditClick,
  onEditChange,
  onEditSubmit,
  onDeleteClick,
  ...props
}) => {
  const { session, local } = useGlobalState()
  const navigate = useNavigate()
  const [editIdx, setEditIdx] = useState(-1)
  const [filterValues, setFilterValues] = useState(getInitFilterVals(session))
  const [pagination, setPagination] = useState(getInitPagination(session))

  const handleEditClick = (item) => {
    onEditClick(item)
    setEditIdx(item.index)
  }

  const cols = getColumns({
    session,
    navigate,
    columns,
    profile,
    showSeq,
    showEditButton,
    showDeleteButton,
    editValues,
    pagination,
    onEditClick: handleEditClick,
    onEditChange,
    onDeleteClick,
  })

  useEffect(() => {
    if (!editValues || Object.keys(editValues).length === 0) setEditIdx(-1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editValues])

  useEffect(() => {
    const pagination = getPagination({ totalCount: rowCount })
    setPagination(pagination)
    session.dispatch({ type: PAGINATION_SET, pagination })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowCount])

  const renderCell = (column, row, index) => {
    if (column.renderHtml) {
      return column.renderHtml({ row, index })
    }
    if (column.render) {
      return column.render({ row, index })
    }
    return row[column.id]
  }

  const renderHeadRow = () => (
    <tr className={style.row} {...props}>
      {cols.map(({ width, align, label, renderHead }, index) => (
        <th
          key={index}
          className={style['table__head-cell']}
          style={{ width: width || 'auto', textAlign: align || 'left' }}
        >
          {renderHead ? renderHead() : label && <Message id={label} />}
        </th>
      ))}
    </tr>
  )

  const renderRows = () => {
    if (!rows || rows.length === 0) return renderEmptyRow()

    return rows.map((row, index) => (
      <tr key={index} index={index} className={style['table__row']}>
        {cols.map((column) => (
          <td
            className={style['table__cell']}
            key={column.id}
            style={{
              width: column.width || 'auto',
              textAlign: column.align || 'left',
              whiteSpace: column.noWrap || 'initial',
            }}
          >
            {editIdx === index
              ? renderEditCell(column, index)
              : renderCell(column, row, index)}
          </td>
        ))}
      </tr>
    ))
  }

  const renderEmptyRow = () => (
    <tr className={style['table__empty-row']}>
      <td colSpan={cols.length}>
        <div>
          <FaInbox size="32px" />
          <Message
            style={{ marginTop: 'var(--space-2)' }}
            id="table.message.empty"
          />
        </div>
      </td>
    </tr>
  )

  const renderAddCell = (column) => {
    const { id, renderInput } = column
    const elementVal = addValues[id]

    if (id === '__SEQ__') return ''
    if (id === '__ACTION__') {
      return (
        <Button
          variant="icon"
          icon={<MdAdd />}
          onClick={() => onAddSubmit({ row: addValues })}
        />
      )
    }

    if (!renderInput) return elementVal

    const input = renderInput({ row: addValues, index: -1 })
    return cloneElement(input, {
      fieldProps: { m: 0 },
      value: elementVal,
      onChange: async (item) => {
        let rowResult = { [id]: item }
        if (input.props.onChange) {
          const result = await input.props.onChange(item)
          rowResult = { ...rowResult, ...result }
        }
        onAddChange({ ...addValues, ...rowResult })
      },
    })
  }

  const renderAddRow = () => {
    if (!showAddInput) return null
    return (
      <tr index={1}>
        {cols.map((column) => (
          <td
            className={style['table__cell']}
            key={column.id}
            style={{ textAlign: 'left', whiteSpace: 'nowrap' }}
          >
            {renderAddCell(column)}
          </td>
        ))}
      </tr>
    )
  }

  const renderEditCell = (column, index) => {
    const { id, renderInput } = column
    const elementVal = editValues[id]

    if (id === '__SEQ__') return index + 1
    if (id === '__ACTION__') {
      return (
        <>
          <Button
            mr={1}
            variant="icon"
            icon={<MdClose />}
            onClick={() => setEditIdx(null)}
          />
          <Button
            variant="icon"
            icon={<MdCheck />}
            onClick={() => {
              onEditSubmit({ row: editValues, index: editIdx })
              setEditIdx(null)
            }}
          />
        </>
      )
    }

    if (!renderInput) return elementVal

    const input = renderInput({ row: editValues, index })
    return cloneElement(input, {
      fieldProps: { m: 0 },
      value: elementVal,
      onChange: async (item) => {
        let rowResult = { [id]: item }
        if (input.props.onChange) {
          const result = await input.props.onChange(item)
          rowResult = { ...rowResult, ...result }
        }
        onEditChange({ ...editValues, ...rowResult }, editIdx)
      },
    })
  }

  const renderPagination = () => {
    if (rowCount === 0) return null
    return (
      <Pagination
        className={{ pagination: style['table__pagination'] }}
        value={pagination}
        onClick={(page) => {
          const pagination = getPagination({ page, totalCount: rowCount })
          setPagination(pagination)
          session.dispatch({ type: PAGINATION_SET, pagination })
          onChange({ session, local, pagination, filterValues })
        }}
      />
    )
  }

  return (
    <div {...props}>
      <Filter
        filters={filters}
        filterValues={filterValues}
        showFilterDrawer={showFilterDrawer}
        onSubmit={({ filterValues }) => {
          setFilterValues(filterValues)
          session.dispatch({ type: FILTER_SET, filterValues })

          return onChange({ local, session, filterValues, pagination })
        }}
      />
      <table className={style.table} {...props}>
        <thead>{renderHeadRow()}</thead>
        <tbody>
          {renderRows(editIdx)}
          {renderAddRow()}
        </tbody>
        {footer && <tfoot>{footer}</tfoot>}
      </table>
      {renderPagination()}
    </div>
  )
}

export default Table
