const product = {
  'module.product': '商品',
  'product.title.list': '商品目錄',
  'product.title.add': '新增商品',
  'product.title.edit': '修改商品',
  'product.title.view': '商品詳情',
  'product.field.name': '商品名稱',
  'product.field.code': '國際條碼',
  'product.field.sku': '品號',
  'product.field.temperature': '溫層',
}

export default product
