const refund = {
  'refund.ticketNo.exist': '原始單號已存在',
  'refund.batchItem.required': '至少新增一項商品',
  'refund.childProduct.quantityInvalid': '組合商品數量有誤',
  'refund.childProduct.typeRequired': '類別為必填欄位',
  'refund.childProduct.locationRequired': '目的地為必填欄位',
  'module.refund': '退貨單',
  'refund.toLocationId.required': '倉別為必填欄位',
  'refund.title.list': '退貨目錄',
  'refund.title.add': '點收商品',
  'refund.title.edit': '點收商品',
  'refund.title.view': '退貨詳情',
  'refund.title.input': '退貨點收',
  'refund.section.applied': '退貨商品',
  'refund.section.received': '點收商品',
  'refund.section.batchItem': '點收商品',
  'refund.field.id': '單號',
  'refund.field.ticketNo': '原始單號',
  'refund.field.contactName': '退貨人名稱',
  'refund.field.receiveType': '類別',
  'refund.field.appliedQuantity': '退貨數量',
  'refund.field.receivedQuantity': '點收數量',
  'refund.field.batchNo': '批號',
  'refund.field.showCombo': '拆解組合',
  'refund.receiveType.RETURN_WAREHOUSE': '轉倉',
  'refund.receiveType.DISCARD': '瑕疵報廢',
  'refund.receiveType.DISCARD_NO_RECYCLE': '報廢不收回',
  'refund.receiveType.RECEIVE_LESS': '數量短收',
  'refund.btn.addChildProduct': '新增商品',
  'refund.btn.done': '強制完成',
  'refund.btn.track': '標記追蹤',
  'refund.title.track': '標記追蹤',
  'refund.message.track': '確認更改訂單狀態為待追蹤？',
  'refund.title.done': '強制完成',
  'refund.message.done': '確認更改訂單狀態為已完成？',
  'refund.status.ACTIVE': '已完成',
  'refund.status.INACTIVE': '已刪除',
  'refund.status.PENDING': '待處理',
  'refund.status.TRACK': '待追蹤',
}

export default refund
